import React from "react";
import Loader from "react-loader-spinner";

const TransparentLoader = () => {
  return (
    <>
      <div
        style={{
          background: "rgba(0,0,0,0.1)",
          width: "100%",
          height: "100%",
          position: "fixed",
          left: "0",
          top: "0",
          zIndex: "999",
        }}
      >
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -50%)",
            top: "50%",
            fontWeight: "400",
          }}
        >
          {/* <Loader type="ThreeDots" color="#fff" /> */}
          {/*  Loading... */}
        </div>
      </div>
    </>
  );
};

export default TransparentLoader;
