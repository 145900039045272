import React, { useEffect, useState } from "react";
// import Logo1 from "../../Asset/RPSG_Group.png";
// import Logo2 from "../../Asset/psrg_logo_2.png";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import * as AppConstant from "../AppConstant";

const LoginForm = ({ onSubmit }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	return (
		<Form onSubmit={onSubmit}>
			<Form.Group controlId="formBasicEmail">
				<Form.Label>Email address</Form.Label>
				<Form.Control
					type="email"
					placeholder="Enter email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</Form.Group>

			<Form.Group controlId="formBasicPassword">
				<Form.Label>Password</Form.Label>
				<Form.Control
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</Form.Group>
			<Form.Group controlId="formBasicCheckbox">
				<Form.Check type="checkbox" label="Remember Me!" />
			</Form.Group>
			<Button variant="primary" type="submit" block>
				Login
      </Button>
		</Form>
	);
};

// handle onScroll event
const handleOnScroll = () => {
	if (window.scrollY === 0) {
		document.body.classList.remove("scrolled");
	}
	else if (window.scrollY >= 50) {
		document.body.classList.add("scrolled");
	}
}

const Header = () => {
	const googleTranslateElementInit = () => {
		new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
	}

	useEffect(() => {
		var addScript = document.createElement('script');
		addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
		document.body.appendChild(addScript);
		window.googleTranslateElementInit = googleTranslateElementInit;
	}, [])


	useEffect(() => {
		window.addEventListener('scroll', handleOnScroll);

		// returned function will be called on component unmount 
		return () => {
			window.removeEventListener('scroll', handleOnScroll);
		}
	}, []);

	const navClick = (e) => {
		e.preventDefault();
		document.querySelector(".navbar-collapse").classList.remove("show");
		document.querySelector(".navbar-toggler").classList.add("collapsed");
		window.scrollTo(0, 0);
	};



	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);

	const onLoginFormSubmit = (e) => {
		e.preventDefault();
		handleClose();
	};

	const location = useLocation();
	return (
		<div className="headerWrap">
			<div className="topNav bg--black">
				<div className="container-fluid">
					<ul className="nav justify-content-end">
						<li className="nav-item">
							<NavLink className="nav-link" to="/career">
								Career
              					</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/faqs">
								FAQs
              					</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/contact">
								Contact
              					</NavLink>
						</li>
						{location.pathname === "/" ? (
							<li className="nav-item">
								{/* <NavLink
									to="http://3.6.52.38:1337/admin/auth/login"
									className="nav-link"
								>
									Employee login
                				</NavLink> */}
								{/* <a href="http://3.6.52.38:1337/admin/auth/login" className="nav-link">
									Employee login
								</a> */}
							</li>
						) : null}
						{/* <li className="nav-item dropdown langDrop">
							<span
								className="nav-link dropdown-toggle border border-warning rounded-pill bg-warning py-0 text-dark text-uppercase mt-2"
								id="navbarDropdown"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<small>En</small>
							</span>
							<div className="dropdown-menu" aria-labelledby="navbarDropdown">
								<span className="dropdown-item">En</span>
								<span className="dropdown-item">Hi</span>
							</div>
						</li> */}
						<li className="nav-item">
							<div id="google_translate_element"></div>
						</li>
					</ul>
				</div>
			</div>
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-bg header">
				<div className="container-fluid">
					<div className="sideMenuIcon"></div>
					{/* <NavLink className="navbar-brand logoMain" to="/">
					<img src={Logo1} alt="logo1" />
				</NavLink> */}
					<a href="https://www.rpsg.in/" className="navbar-brand logoMain">
						<img src={AppConstant.LOGO_RSGP} alt="logo1" />
					</a>

					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav mx-auto">
							{/* <li className="nav-item">
              					<NavLink exact className="nav-link text-uppercase" to="/">Home</NavLink>
            				</li> */}
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/">
									Home
              					</NavLink>
							</li>
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/about-rp-sanjiv-goenka-group">
									RP-SG Group
              					</NavLink>
							</li>
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/about-us">
									About Us
              					</NavLink>
							</li>
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/product">
									Products
              					</NavLink>
							</li>
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/power">
									Power
              					</NavLink>
							</li>
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/investor-relation">
									Investor Relations
              					</NavLink>
							</li>
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/responsibility">
									Sustainability
              					</NavLink>
							</li>
							<li className="nav-item" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/communication">
									Newsroom
              					</NavLink>
							</li>
							<li className="nav-item mobileOnly" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/career">
									Career
              					</NavLink>
							</li>
							<li className="nav-item mobileOnly" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/faqs">
									FAQs
              					</NavLink>
							</li>
							<li className="nav-item mobileOnly" onClick={e => navClick(e)}>
								<NavLink className="nav-link" to="/contact">
									Contact
              					</NavLink>
							</li>
							{location.pathname === "/" ? (
								<li className="nav-item mobileOnly" onClick={e => navClick(e)}>
									{/* <NavLink
										to="/"
										className="nav-link"
										onClick={handleShow}
									>
										Employee login
                					</NavLink> */}
									<a href="http://3.6.52.38:1337/admin/auth/login" className="nav-link">
										Employee login
									</a>
								</li>
							) : null}
							{/* <li className="nav-item dropdown langDrop mobileOnly">
								<span
									className="nav-link dropdown-toggle border border-warning rounded-pill bg-warning py-0 text-dark text-uppercase mt-2"
									id="navbarDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<small>En</small>
								</span>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<span className="dropdown-item">En</span>
									<span className="dropdown-item">Hi</span>
								</div>
							</li> */}
							<li className="nav-item mobileOnly" onClick={e => navClick(e)}>
								<div id="google_translate_element"></div>
							</li>
						</ul>
					</div>

					<div className="d-xl-block carbonLogo">
						<NavLink to="/">
							<img src={AppConstant.LOGO_PCBL} alt="logo2" />
						</NavLink>
					</div>
					<button
						className="navbar-toggler collapsed"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
				</div>
				<Modal
					show={show}
					onHide={handleClose}
					centered
					size="sm"
					className="customlogin"
				>
					<Modal.Header closeButton>
						<Modal.Title>Employee Login</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<LoginForm onSubmit={onLoginFormSubmit} />
					</Modal.Body>
				</Modal>
			</nav>

		</div >
	);
};

export default Header;