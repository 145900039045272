import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import Popper from 'popper.js';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import Routes from "./Components/Common/Routes/Routes";
import ReactGA from "react-ga";
// import * as AppConstant from "../src/Components/AppConstant";
import Axios from "./Services/Shared/Axios";

const App = () => {
  useEffect(() => {
    Axios({
      method: "GET",
      url: "/others-site-settings",
    })
      .then((res) => {
        if (res.data) {
          ReactGA.initialize(res.data.GoogleAnalyticsTrackingCode);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      })
      .catch((err) => {
        //console.log("Err=>", err);
      });
  }, []);
  return (
    <>
      <Routes />
    </>
  );
};

export default App;
